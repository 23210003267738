nav{
  z-index: 1030!important;
  position: fixed!important;
  background-color: $brand-primary!important;
  width: 100%;
  .navbar-brand {
    background: url(../img/sprite-with-gray-icons.png) no-repeat;
    background-position: -331px -480px;
    display: block;
    background-size: inherit;
    color: #fff!important;
    padding-left: 70px;
    padding-top: 20px;

    img {
      max-width: 60px;
      display: inline-block !important;
      vertical-align: middle;
    }
  }
}



.navbar-nav {
  margin-left: 40px;
  li{
    a{
      color:#fff!important;
    }
    &.active {
      a {
        background: transparent !important;
        color: #fff !important;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  &.navbar-right{
    li.dropdown.open{
      a{
        background: transparent !important;
      }
    }
    .dropdown-menu{
      margin-top: 4px;
      li {
        a {
          color: #333 !important;
        }
      }
    }


  }
}

.nav-tabs {
  li {
    a {
      font-weight: 600;
    }
  }
}

#app-navbar-collapse {
  padding: 5px 0;
}

h1.page-header{
  margin-top: 0!important;
}