
// Fonts
body{
  font-family: sans-serif;
  line-height: 1.6;
  color: #636b6f;
  background-color: #f5f8fa;
  font-size: 14px;
  font-weight: 300;
}

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "home";
@import "messages";
@import "queries";
@import "profile";
@import "header";
@import "modal";
@import "upload";
