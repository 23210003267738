.profile{
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
    background: #ffffff;
  }
  .tab-content{
    background: #fff;
    padding: 25px;
    border: 1px solid #ddd;
    border-top: none;
  }
}