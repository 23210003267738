.all-messages{
  table.messages{
    td{
      vertical-align: middle;
      .btn-group{
        a{
          padding: 3px 15px;
          font-size: 12px;
          span{
            top:2px;
          }
        }
      }
    }

  }

}

.panel-heading{
  font-weight: bold;
}


.nav-container{
  padding: 0 50px;
  @media (max-width: 767px) {
    padding: 0 10px;
  }
}


.client-messages{
  padding: 0 50px;
  @media (max-width: 767px) {
    padding: 0 10px;
  }

  .table-responsive{
    @media (max-width: 767px) {
      border: none;
    }
  }

  #message-content{
    border: 1px solid #dedede;
    width: 100%;
    min-height: 450px;
  }
  h4{
    background: $brand-primary;
    padding: 7px;
    margin: 0;
    color: #fff;
  }

  table#messages-table{
    tr{
      cursor: pointer;
      &.selected {
        background-color: #B0BED9;
      }

      &.unread {
        font-weight: 700;
      }
    }
  }


  #messages-table_wrapper {
    > .row:first-child {
      .col-sm-6 {
        width: 100%;
        padding: 0;
      }
    }
  }
  #messages-table_filter {
    input {
      @media (min-width: 768px) and (max-width: 800px) {
        width: 100px;
      }
    }
  }
  .dataTables_scrollHeadInner, .dataTable {
    width: 100% !important;
  }


  .top-actions{
    &__left{
      @media (max-width: 767px) {
        text-align: center;
      }
      button{
        @media (max-width: 767px) {
          margin-bottom: 5px;
        }
      }
    }
    &__right{
      span.label{
        @media (max-width: 767px) {
          margin-bottom: 5px;
          display: block;
        }
      }
    }
  }

}